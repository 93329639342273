import React from 'react';
import s from './CitizensAppeals.module.css';
import ContantContainerMain from '../../total/ContantContainerMain';

/* import { NavLink } from "react-router-dom"; */
import { ROUTER } from '../../config';

import LinkContainer from '../../Components/LinkContainer';

const CitizensAppeals = (props) => {
   return (
      <div>
         <ContantContainerMain>
            <div className="mt80 pageTitle">Работа с обращениями граждан</div>
            <div className={`mt80 borderMain ${s.linkContainer}`}>
               <LinkContainer link={ROUTER.citizensAppeals.legalRegulation} text={'Правовое регулирование'} />
               <div className="mt24"><LinkContainer link={ROUTER.citizensAppeals.receptionProcedureTime} text={'Порядок и время приема'} /></div>
               {/* <div className="mt24"><LinkContainer link={ROUTER.citizensAppeals.virtualReceptionHeadCityDistrict} text={'Виртуальная приемная Главы городского округа'} /></div> */}
               <div className="mt24"><LinkContainer link={"https://old.admhimki.ru/okrug/rabota-s-obrasheniyami-grazhdan/rezultaty-rassmotreniya-obrashenij/"} /* link={ROUTER.citizensAppeals.resultsConsiderationApplications} */ text={'Результаты рассмотрения обращений'} /></div>
               <div className="mt24"><LinkContainer link={ROUTER.citizensAppeals.writeAdministration} text={'Написать в Администрацию'} /></div>
               <div className="mt24"><LinkContainer link={ROUTER.citizensAppeals.allRussianDayReceptionCitizens} text={'Общероссийский день приёма граждан в День Конституции Российской Федерации 12 декабря'} /></div>
               <div className="mt24"><LinkContainer link={ROUTER.citizensAppeals.informationForReview} text={'Информация для ознакомления желающим отправить обращение в форме электронного документа'} /></div>
               <div className="mt24"><LinkContainer link={ROUTER.citizensAppeals.responsesInquiriesIndefiniteNumberPersons} text={'Ответы на обращения, затрагивающие интересы неопределенного круга лиц'} /></div>
               <div className="mt24"><LinkContainer link={ROUTER.citizensAppeals.visitingAdministration} text={'Выездная администрация'} /></div>
            </div>

            <div className="mt80 text">
               <div className="pageSubtitle">Прием письменных обращений юридических и физических лиц <br /> в администрации городского округа химки московской области</div>

               <p>
                  Режим работы: <br />
                  Пн-Чт 09:00–17:30, перерыв 13:00–14:00;<br />
                  Пт 09:00–16:30, перерыв 13:00–14:00<br />
               </p>

               <p>
                  Письмом: <br />
                  Московская область, г. Химки, ул. Кирова, д. 30
                  («Муниципальный центр <br /> управления регионом»)
               </p>

               <div className="mt38 pageSubtitle">Требования к письменному обращению</div>

               <p>
                  Администрацией рассматриваются индивидуальные и коллективные обращения, включая обращения объединений граждан, в том числе юридических лиц по вопросам, находящимся в ее компетенции.
               </p>

               <p>
                  В случае, если в Администрацию направляется обращение, затрагивающее права третьих лиц, такое обращение может быть рассмотрено при обязательном предоставлении оформленной в установленном порядке доверенности от такого лица либо предоставлен ответ, содержащий общую правовую информацию.
               </p>

               <div className="mt38 pageSubtitle">Порядок рассмотрения отдельных обращений</div>

               <ol className="mt38">
                  <li>В случае, если в письменном обращении не указаны фамилия гражданина, направившего обращение, или почтовый адрес, по которому должен быть направлен ответ, ответ на обращение не дается. Если в указанном обращении содержатся сведения о подготавливаемом, совершаемом или совершенном противоправном деянии, а также о лице, его подготавливающем, совершающем или совершившем, обращение подлежит направлению в государственный орган в соответствии с его компетенцией.</li>
                  <li>Обращение, в котором обжалуется судебное решение, в течение семи дней со дня регистрации возвращается гражданину, направившему обращение, с разъяснением порядка обжалования данного судебного решения.</li>
                  <li>Государственный орган, орган местного самоуправления или должностное лицо при получении письменного обращения, в котором содержатся нецензурные либо оскорбительные выражения, угрозы жизни, здоровью и имуществу должностного лица, а также членов его семьи, вправе оставить обращение без ответа по существу поставленных в нем вопросов и сообщить гражданину, направившему обращение, о недопустимости злоупотребления правом.</li>
                  <li>В случае, если текст письменного обращения не поддается прочтению, ответ на обращение не дается и оно не подлежит направлению на рассмотрение в государственный орган, орган местного самоуправления или должностному лицу в соответствии с их компетенцией, о чем в течение семи дней со дня регистрации обращения сообщается гражданину, направившему обращение, если его фамилия и почтовый адрес поддаются прочтению.</li>
                  <li>В случае, если в письменном обращении гражданина содержится вопрос, на который ему неоднократно давались письменные ответы по существу в связи с ранее направляемыми обращениями, и при этом в обращении не приводятся новые доводы или обстоятельства, руководитель государственного органа или органа местного самоуправления, должностное лицо либо уполномоченное на то лицо вправе принять решение о безосновательности очередного обращения и прекращении переписки с гражданином по данному вопросу при условии, что указанное обращение и ранее направляемые обращения направлялись в один и тот же государственный орган, орган местного самоуправления или одному и тому же должностному лицу. О данном решении уведомляется гражданин, направивший обращение.</li>
                  <li>В случае, если ответ по существу поставленного в обращении вопроса не может быть дан без разглашения сведений, составляющих государственную или иную охраняемую федеральным законом тайну, гражданину, направившему обращение, сообщается о невозможности дать ответ по существу поставленного в нем вопроса в связи с недопустимостью разглашения указанных сведений.</li>
                  <li>В случае, если причины, по которым ответ по существу поставленных в обращении вопросов не мог быть дан, в последующем были устранены, гражданин вправе вновь направить обращение в соответствующий государственный орган, орган местного самоуправления или соответствующему должностному лицу.</li>
               </ol>

               <div className="mt38 pageSubtitle">Сроки рассмотрения письменного обращения</div>

               <ol className="mt38">
                  <li>Письменное обращение, поступившее в государственный орган, орган местного самоуправления или должностному лицу в соответствии с их компетенцией, рассматривается в течение 30 дней со дня регистрации письменного обращения, за исключением случая, когда письменное обращение, поступившее высшему должностному лицу субъекта Российской Федерации (руководителю высшего исполнительного органа государственной власти субъекта Российской Федерации) и содержащее информацию о фактах возможных нарушений законодательства Российской Федерации в сфере миграции, рассматривается в течение 20 дней со дня регистрации письменного обращения.</li>
                  <li>В исключительных случаях должностное лицо либо уполномоченное на то лицо вправе продлить срок рассмотрения обращения не более чем на 30 дней, уведомив о продлении срока его рассмотрения гражданина, направившего обращение.</li>
                  <li>Государственный орган, орган местного самоуправления или должностное лицо по направленному в установленном порядке запросу государственного органа, органа местного самоуправления или должностного лица, рассматривающих обращение, обязаны в течение 15 дней предоставлять документы и материалы, необходимые для рассмотрения обращения, за исключением документов и материалов, в которых содержатся сведения, составляющие государственную или иную охраняемую федеральным законом тайну, и для которых установлен особый порядок предоставления.</li>
               </ol>

               <div className="mt38 pageSubtitle">Устные обращения</div>

               <div className="mt38 fw600">Порядок личного приёма</div>

               <ul className="mt38">
                  <li>
                     При личном приеме гражданин предъявляет документ, удостоверяющий его личность.
                  </li>
                  <li>
                     Содержание устного обращения заносится в карточку личного приема гражданина. В случае, если изложенные в устном обращении факты и обстоятельства являются очевидными и не требуют дополнительной проверки, ответ на обращение с согласия гражданина может быть дан устно в ходе личного приема, о чем делается запись в карточке личного приема гражданина. В остальных случаях дается письменный ответ по существу поставленных в обращении вопросов.
                  </li>
                  <li>
                     Письменное обращение, принятое в ходе личного приема, подлежит регистрации и рассмотрению в установленном порядке.
                  </li>
                  <li>
                     В случае, если в обращении содержатся вопросы, решение которых не входит в компетенцию Администрации или должностного лица, гражданину дается разъяснение о порядке обращения в организацию, компетентную в решении его вопросов.
                  </li>
               </ul>

            </div>

         </ContantContainerMain>
      </div>
   )
}
export default CitizensAppeals;